import { mainServerAxios } from ".";
import { loginAPIRoute } from "./functions/loginAPIRoute";

export const getCreditsUsingSessionId = (sessionId) =>
  mainServerAxios.get(loginAPIRoute(`/recentcredits/${sessionId}`));

export const getUserStatsAPI = () =>
  mainServerAxios.get(loginAPIRoute("/userstats"));

export const getServicesUsageForChartAPI = () =>
  mainServerAxios.get(loginAPIRoute("/apivstimegraph"));

export const getCreditsHistoryAPI = () =>
  mainServerAxios.get(loginAPIRoute("/credithistory"));

export const getUsersServicesUsageDataAPI = () =>
  mainServerAxios.get(loginAPIRoute("/user_data"));
