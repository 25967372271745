const constant = {
  role: {
    ROOT: "root",
    SUB_USER: "iam",
  },

  months: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  algoLogoPath: "/Images/algoLogo.jpeg",
  algoNameLogo: "/Images/algoNameLogo.png",
};

module.exports = constant;
