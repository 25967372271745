import classes from "./ripple-loader.module.scss";

function RippleLoader() {
    return (
        <div className={classes.container}>
            <div className={classes.ldsRipple}>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default RippleLoader;
