import { loginAPIRoute } from "./functions/loginAPIRoute";
import { mainServerAxios } from "./index";

export const getSubUsersAPI = () =>
  mainServerAxios.get(loginAPIRoute("/getsubusers"));

export const createSubUserAPI = (data) =>
  mainServerAxios.post(loginAPIRoute("/create_subusers"), data);

export const activeDeactiveSubUserAPI = (data) =>
  mainServerAxios.post(loginAPIRoute("/activatedeactive"), data, {
    header: { "Content-Type": "application/x-www-form-urlencoded" },
  });

export const updateSubUserPermissionsAPI = (data, username) =>
  mainServerAxios.post(
    loginAPIRoute(`/updatePermission?username=${username}`),
    data
  );

export const resendEmailAPI = (data) =>
  mainServerAxios.post(loginAPIRoute(`/resendemail`), data);

export const deleteNVUserAPI = (username) =>
  mainServerAxios.delete(loginAPIRoute(`/deleteNVUser?username=${username}`));
