import { useSearchParams } from "react-router-dom";
import { getCreditsUsingSessionId } from "../../API/dashboard";
import { dashboardContextConstants } from "./constants";

const { createContext, useContext, useState, useEffect } = require("react");

const dashboardContext = createContext();

export const DashboardContextProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // To check weather type of data loaded in all tabs of dashboard
  const [dashboardDataLoaded, setdashboardDataLoaded] = useState({});

  const addDashboardDataLoaded = (type) => {
    setdashboardDataLoaded((prev) => ({ ...prev, [type]: true }));
  };

  // Dashboard
  const [userStatsData, setUserStatsData] = useState({});
  const [servicesAPIUsage, setServicesAPIUsage] = useState([]);
  const [creditsHistoryData, setCreditsHistoryData] = useState([]);
  const [usersServicesUsageData, setUsersServicesUsageData] = useState([]);

  // User management
  const [subUsers, setSubUsers] = useState([]);

  //Plans
  const [plans, setPlans] = useState([]);

  //Payment success
  const [successPaymentModalState, setSuccessPaymentModalState] = useState(
    dashboardContextConstants.initialPaymentStates
  );

  useEffect(() => {
    const checkPaymentsSuccessfull = async (session_id) => {
      try {
        const response = await getCreditsUsingSessionId(session_id);
        setSuccessPaymentModalState({
          open: true,
          credits: response.data.credits,
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (searchParams.get("payment_successfull") === "true") {
      const session_id = searchParams.get("session_id");
      if (!session_id) return;
      checkPaymentsSuccessfull(session_id);
    }
  }, [searchParams]);

  const closeSuccessPaymentModal = () => {
    setSuccessPaymentModalState(dashboardContextConstants.initialPaymentStates);
    setSearchParams({});
  };

  return (
    <dashboardContext.Provider
      value={{
        dashboardDataLoaded,
        addDashboardDataLoaded,

        plans,
        setPlans,
        successPaymentModalState,
        closeSuccessPaymentModal,

        userStatsData,
        setUserStatsData,

        servicesAPIUsage,
        setServicesAPIUsage,
        creditsHistoryData,
        setCreditsHistoryData,
        usersServicesUsageData,
        setUsersServicesUsageData,

        subUsers,
        setSubUsers,
      }}
    >
      {children}
    </dashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return useContext(dashboardContext);
};
