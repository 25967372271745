import { loginAPIRoute } from "./functions/loginAPIRoute";
import { mainServerAxios } from "./index";

export const signupAPI = (data) =>
  mainServerAxios.post(loginAPIRoute("/signup"), data);

export const loginAPI = (data) =>
  mainServerAxios.post(loginAPIRoute("/token"), data);

export const subuserSetPasswordAPI = (token, data) =>
  mainServerAxios.post(loginAPIRoute(`/subuser/${token}`), data);

export const getCurrentUserAPI = () =>
  mainServerAxios.get(loginAPIRoute("/current_user"));

export const verifyEmailAPI = (token) =>
  mainServerAxios.get(loginAPIRoute(`/user/${token}`));

export const sendRecoverPasswordLinkAPI = (data) =>
  mainServerAxios.post(loginAPIRoute("/forgotPassword"), data);

export const resetPasswordAPI = (token, data) =>
  mainServerAxios.post(loginAPIRoute(`/resetPassword/${token}`), data);

export const getBillingDetailsAPI = () =>
  mainServerAxios.get(loginAPIRoute("/getBillingDetails"));

export const updateCompanyDetailsAPI = (data) =>
  mainServerAxios.post(loginAPIRoute("/updateCompanyDetails"), data);

export const updatePersonalInfoAPI = (data) =>
  mainServerAxios.post(loginAPIRoute("/user/personalinfo"), data);

export const getServicesAPI = () =>
  mainServerAxios.get(loginAPIRoute("/getServices"));

export const getCompanyDetailsAPI = () =>
  mainServerAxios.get(loginAPIRoute("/getCompanyDetails"));
