export const dashboardContextConstants = {
  initialPaymentStates: {
    open: false,
    credits: 0,
  },
};

export const globalDataLoadTypes = {
  PERSONAL_INFO: "personalInfo",
  COMPANY_INFO: "companyInfo",
};

export const dashboardDataLoadTypes = {
  USER_STATS: "userStats",
  SERVICES_API_USAGE: "servicesAPIUsage",
  CREDITS_HISTORY: "creditsHistory",
  USERS_SERVICES_USAGE: "usersServicesUsage",
  PLANS: "plans",
  SUBUSERS: "subUsers",
};
