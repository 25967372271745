import { useState } from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Header from "./DashboardHeader";
import Sidenav from "./Sidenav";
import classes from "./dashboardLayout.module.scss";
import { useAuthContext } from "../../Auth/AuthContext";
import constant from "../../../constant";
import { plansPathname } from "./constants";
import { DashboardContextProvider } from "../../contexts/dashboardContext";
import PaymentSuccessModal from "../../PaymentSuccessModal";

const { Header: AntHeader, Content, Sider } = Layout;

function DashboardLayout() {
  const { user, authLoading } = useAuthContext();

  //SIdenav configs
  const [visible, setVisible] = useState(false);
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [fixed, setFixed] = useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  const { pathname } = useLocation();

  if (
    // Which means after token verification done only user value needs to be checked
    // ELse it will redirect initial itself
    !authLoading &&
    (!user ||
      !user?.profile_updated ||
      (user?.role === constant.role.ROOT && !user?.company_info_updated))
  ) {
    return <Navigate to={"/"} />;
  }

  return (
    <DashboardContextProvider>
      <Layout
        className={`${classes["layout-dashboard"]} ${
          pathname === plansPathname ? classes["layout-plans"] : ""
        }`}
      >
        <PaymentSuccessModal />

        {/* Sidebar for small scrren */}
        <Drawer
          title={false}
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
          width={250}
          className={classes[`drawer-sidebar`]}
          placement="left"
          key={"right"}
        >
          <Layout className={classes[`layout-dashboard`]}>
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className={`${classes["sider-primary"]} ${classes["ant-layout-sider-primary"]}`}
            >
              <Sidenav color={sidenavColor} />
            </Sider>
          </Layout>
        </Drawer>

        {/* Sidebar for big scrren */}
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          width={250}
          theme="light"
          className={`${classes["sider-primary"]} ${classes["ant-layout-sider-primary"]}`}
        >
          <Sidenav color={sidenavColor} />
        </Sider>

        <Layout className={classes.contentLayout}>
          {fixed ? (
            <Affix className={classes.affix}>
              <AntHeader className={classes.mainHeader}>
                <Header
                  onPress={openDrawer}
                  handleSidenavColor={handleSidenavColor}
                  handleFixedNavbar={handleFixedNavbar}
                />
              </AntHeader>
            </Affix>
          ) : (
            <AntHeader className={classes.mainHeader}>
              <Header
                onPress={openDrawer}
                handleSidenavColor={handleSidenavColor}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          )}

          <Content className={classes.mainContent}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </DashboardContextProvider>
  );
}

export default DashboardLayout;
