import { useDashboardContext } from "../contexts/dashboardContext";
import LottieAnimationPlayer from "../Reusable/LottieAnimationPlayer";
import { Divider, Modal, Space } from "antd";
import classes from "./paymentSuccessModal.module.scss";

function PaymentSuccessModal() {
  const { successPaymentModalState, closeSuccessPaymentModal } =
    useDashboardContext();

  return (
    <Modal
      visible={successPaymentModalState.open}
      onCancel={closeSuccessPaymentModal}
      footer={null}
      bodyStyle={{ padding: "40px 20px" }}
      destroyOnClose
    >
      <LottieAnimationPlayer
        type="success"
        dimension={"100px"}
        loop={false}
        text="Payment successfull"
        textStyle={{ color: "green" }}
      />
      <Divider className={classes.Divider}>Credits</Divider>
      <Space
        direction="vertical"
        align="center"
        className={`full-width ${classes.creditData}`}
      >
        <div className={classes.credits}>
          {successPaymentModalState.credits}
        </div>
        <div className={classes.otherData}>
          Has been added to your account. Thank you
        </div>
      </Space>
    </Modal>
  );
}

export default PaymentSuccessModal;
