import { Modal, Steps } from "antd";
import classes from "./personalInfo.module.scss";
import { useAuthContext } from "../Auth/AuthContext";
import constant from "../../constant";
import PersonalInfoForm from "../Reusable/UserForms/PersonalInfoForm";
import CompanyInfoForm from "../Reusable/UserForms/CompanyInfoForm";
import {
  AuditOutlined,
  CheckCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import CompletedResult from "./CompletedResult";

const modalBodyStyle = {
  padding: "5% 10%",
};

const ProfileInfo = ({ visible, onCancel }) => {
  const { user } = useAuthContext();

  const isRootUser = user?.role === constant.role.ROOT;
  const isProfileUpdated = user?.profile_updated;
  const isCompanyDetailsUpdated = user?.company_info_updated;

  const type = !isProfileUpdated
    ? "profile"
    : isProfileUpdated && isRootUser && !isCompanyDetailsUpdated
    ? "company"
    : "";

  return (
    <Modal
      header={false}
      footer={false}
      onCancel={onCancel}
      visible={visible}
      width={600}
      className={classes.modalWrapper}
      destroyOnClose
      bodyStyle={modalBodyStyle}
    >
      <Steps
        current={type === "profile" ? 0 : type === "company" ? 1 : 2}
        type="navigation"
        size="small"
        style={{ marginBottom: "30px" }}
      >
        <Steps.Step title="Personal Info" icon={<UserOutlined />} />
        {isRootUser && (
          <Steps.Step title="Company Info" icon={<AuditOutlined />} />
        )}
        <Steps.Step title="Complete" icon={<CheckCircleOutlined />} />
      </Steps>

      {/* <div className={classes.titleWrapper}>
        {!isProfileUpdated ? "Personal Information" : "Company Information"}
      </div> */}

      {type === "profile" ? (
        <PersonalInfoForm />
      ) : type === "company" ? (
        <CompanyInfoForm />
      ) : (
        <CompletedResult />
      )}
    </Modal>
  );
};
export default ProfileInfo;
