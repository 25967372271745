import { message } from "antd";

export const handleErrors = (err, optionalMessage, key) => {
  console.log(err);

  const errorMessage =
    optionalMessage ||
    err?.response?.data?.detail ||
    err?.message ||
    "Something went wrong!";

  if (!key) {
    message.error(errorMessage);
  } else {
    message.error({ content: errorMessage, key, duration: 5 });
  }
};
