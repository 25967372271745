import { Input, Form } from "antd";
import LocationsSelectFormItem from "../LocationsSelectFormItem";

const PhoneInput = ({
  mobileNumberPlaceholder = "Enter mobile number",
  label = "Phone number",
  withCountry = true,
  disabled,
}) => {
  return (
    <>
      {withCountry && (
        <LocationsSelectFormItem
          type={"phoneCode"}
          name="country_mobile_code"
          disabled={disabled}
          rules={[{ required: true, message: "Please select code" }]}
        />
      )}
      <Form.Item
        name="mobile_number"
        rules={[
          {
            required: true,
            message: "Please Enter mobile number",
          },
          {
            pattern: /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im,
            message: "Please enter valid mobile number",
          },
        ]}
        label={label}
      >
        <Input placeholder={mobileNumberPlaceholder} disabled={disabled} />
      </Form.Item>
    </>
  );
};
export default PhoneInput;
