import { Button, Divider, Row, Space, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import DashboardButton from "./DashboardButton";
import classes from "./header.module.scss";
import { useAuthContext } from "../../../Auth/AuthContext";
import UserProfileMenus from "../../UserProfileMenus";
import constant from "../../../../constant";

function MainHeader() {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <Row
      className={`${classes.mainHeader}`}
      justify="space-around"
      align="middle"
    >
      <Tooltip title="By algoscale">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://algoscale.com"
          style={{ fontSize: "1.3rem" }}
        >
          <img
            loading="lazy"
            className={classes.headerLogo}
            src={constant.algoNameLogo}
            alt=""
          />
        </a>
      </Tooltip>
      <Space size={0} align="center" split={<Divider type="vertical" />}>
        {user && <DashboardButton />}
        <Link to="/">
          <span className={classes.headerTitle}>AssemblAI</span>
        </Link>
        {user ? (
          <UserProfileMenus />
        ) : (
          <Button type="primary" style={{ width: "80px" }} onClick={goToLogin}>
            Login
          </Button>
        )}
      </Space>
    </Row>
  );
}

export default MainHeader;
