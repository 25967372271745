import { Form, Select } from "antd";
import { Country, State } from "country-state-city";
import { useState } from "react";

function LocationsSelectFormItem({
  type,
  countryCode,
  placeholder,
  selectOnChange,
  label,
  name,
  rules,
  populateStates,
  populateStatesName = "state",
  populateStatesLabel = "State",
  populateStatesRules,
  disabled,
  ...props
}) {
  const [data] = useState(
    type === "country" || type === "phoneCode"
      ? Country.getAllCountries()
      : type === "state"
      ? State.getStatesOfCountry(countryCode)
      : []
  );
  const [populatedStates, setPopulatedStates] = useState([]);

  const handleCountrySelect = (countryData) => {
    countryData = JSON.parse(countryData);
    setPopulatedStates(State.getStatesOfCountry(countryData.code));
  };

  return (
    <>
      <Form.Item
        name={name || type}
        label={
          type === "country"
            ? "Country"
            : type === "state"
            ? "State"
            : type === "phoneCode"
            ? "Country code"
            : ""
        }
        rules={rules}
        {...props}
      >
        <Select
          placeholder={
            placeholder || type === "country"
              ? "Select country"
              : type === "state"
              ? "Select state"
              : type === "phoneCode"
              ? "Select country code"
              : ""
          }
          showSearch
          optionFilterProp="children"
          onChange={populateStates && handleCountrySelect}
          disabled={disabled}
        >
          {data.map((item) => (
            <Select.Option
              key={item.phoneCode || item.isoCode}
              value={
                type === "phoneCode"
                  ? item.phoneCode
                  : JSON.stringify({
                      name: item.name,
                      code: item.isoCode,
                    })
              }
            >
              {type === "phoneCode"
                ? `${item?.phonecode} (${item?.name})`
                : item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {populateStates && (
        <Form.Item
          label={populateStatesLabel}
          name={populateStatesName}
          rules={populateStatesRules}
        >
          <Select
            placeholder="Select state"
            showSearch
            optionFilterProp="children"
            disabled={disabled}
          >
            {populatedStates.map((state) => (
              <Select.Option
                key={state.isoCode}
                value={JSON.stringify({
                  name: state.name,
                  code: state.isoCode,
                })}
              >
                {state.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
}

export default LocationsSelectFormItem;
