import { sidenavIcons } from "./icons";

export const ApiDocsLink =
  "https://documenter.getpostman.com/view/21916135/VUxKVA2T";

export const plansPathname = "/dashboard/plans";

export const sideNavMenuItems = {
  main: [
    {
      key: 1,
      link: "/dashboard/dashboard",
      icon: sidenavIcons.dashboard,
      label: "Dashboard",
    },
    {
      key: 2,
      link: "/dashboard/user-managment",
      icon: sidenavIcons.profile,
      label: "User Managment",
    },
    {
      key: 3,
      link: "/dashboard/plans",
      icon: sidenavIcons.billing,
      label: "Plans",
    },
    {
      key: 4,
      link: "/",
      icon: sidenavIcons.home,
      label: "Services",
    },
    //These are samples
    // {
    //   key: 1001,
    //   link: "/dashboard/sample",
    //   icon: sidenavIcons.dashboard,
    //   label: "Sample Dashboard",
    // },
    // {
    //   key: 1002,
    //   link: "/dashboard/sample-tables",
    //   icon: sidenavIcons.tables,
    //   label: "Sample Table",
    // },
    // {
    //   key: 1003,
    //   link: "/dashboard/sample-billing",
    //   icon: sidenavIcons.billing,
    //   label: "Sample Billing",
    // },
  ],
  account: [
    {
      key: 201,
      link: "/dashboard/profile",
      icon: sidenavIcons.profile,
      label: "Profile",
    },
    // {
    //   key: 201,
    //   link: "/dashboard/sample-profile",
    //   icon: sidenavIcons.profile,
    //   label: "Sample Profile",
    // },
    // {
    //   key: 202,
    //   link: "/sample-signin",
    //   icon: sidenavIcons.signin,
    //   label: "Sample Signin",
    // },
    // {
    //   key: 203,
    //   link: "/sample-signup",
    //   icon: sidenavIcons.signup,
    //   label: "Sample Signup",
    // },
  ],
};
