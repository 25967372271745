import { message } from "antd";
import { createContext, useContext, useEffect, useState } from "react";
import { getCurrentUserAPI } from "../../API/login";
import { useModalState } from "../../customHooks/useModalState";

const authContext = createContext();

// const sampleProfileData = {
//   username: "test@algoscale.com",
//   role: "root",
//   profile_updated: true,
//   company_info_updated: true,
// };

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [showCompleteProfile, openCompleteProfile, closeCompleteProfile] =
    useModalState(false);

  const setCurrentUser = async () => {
    if (
      !localStorage.getItem("access_token") &&
      !sessionStorage.getItem("access_token")
    ) {
      setAuthLoading(false);
      return;
    }
    try {
      const { data } = await getCurrentUserAPI();
      setUser({ ...data,
        "profile_updated": true,
        "company_info_updated": true
    });
    } catch (error) {
      //Log error other than anauthprised...It might server problem
      if (error?.response?.status !== 401) {
        console.log(error);
      }
    }
    setAuthLoading(false);
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    sessionStorage.removeItem("access_token");
    setUser(null);
    message.success("Successfully logged out");
  };

  useEffect(() => {
    setCurrentUser();
  }, []);

  const setProfileUpdated = () => {
    setUser((prev) => ({ ...prev, profile_updated: true }));
  };

  const setCompanyInfoUpdated = () => {
    setUser((prev) => ({ ...prev, company_info_updated: true }));
  };

  const changeUserName = (first_name, last_name) => {
    if (first_name !== user.first_name || last_name !== user.last_name) {
      setUser((prev) => ({ ...prev, first_name, last_name }));
    }
  };

  return (
    <authContext.Provider
      value={{
        user,
        authLoading,
        setCurrentUser,
        logout,
        showCompleteProfile,
        openCompleteProfile,
        closeCompleteProfile,
        setProfileUpdated,
        setCompanyInfoUpdated,
        changeUserName
      }}
    >
      {children}
    </authContext.Provider>
  );
};

const useAuthContext = () => {
  return useContext(authContext);
};

export { AuthProvider, useAuthContext };
