import classes from "./spinner.module.scss";
import SpinnerAnimationData from "../../../assets/lottie/spinner.json";
import LottieAnimationPlayer from "../LottieAnimationPlayer";

function Spinner({ containFully = true, text, width }) {
  return (
    <div
      className={`${classes.container} ${containFully && classes.containFully}`}
    >
      <LottieAnimationPlayer
        animationData={SpinnerAnimationData}
        width={width || "200px"}
        loop
      />
      {text && <div className={classes.text}>{text}</div>}
    </div>
  );
}

export default Spinner;
