import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../Auth/AuthContext";

function CompletedResult() {
  const navigate = useNavigate();
  const { closeCompleteProfile } = useAuthContext();

  const goToDashboard = () => {
    navigate("/dashboard/dashboard");
    closeCompleteProfile();
  };

  return (
    <Result
      status="success"
      title="Successfully added data"
      subTitle="Your profile completed successfully...Now you can visit your dashboard"
      extra={[
        <Button onClick={closeCompleteProfile}>Close</Button>,
        <Button type="primary" onClick={goToDashboard}>
          Dashboard
        </Button>,
      ]}
    />
  );
}

export default CompletedResult;
