import { Space } from "antd";
import Lottie from "react-lottie";
import successAnimationData from "../../../assets/lottie/success.json";
import errorAnimationData from "../../../assets/lottie/error.json";

// if dimension passes then it considered as both width and height
function LottieAnimationPlayer({
  animationData,
  type,
  loop = false,
  autoplay = true,
  dimension,
  height,
  width,
  text,
  textStyle = {},
}) {
  return (
    <Space align="center" direction="vertical" style={{ width: "100%" }}>
      <Lottie
        options={{
          animationData: animationData
            ? animationData
            : type === "success"
            ? successAnimationData
            : type === "error"
            ? errorAnimationData
            : "",
          loop,
          autoplay,
        }}
        height={dimension || height}
        width={dimension || width}
      />
      {text && (
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: "500",
            color: "#4d4a4a",
            textAlign: "center",
            ...textStyle,
          }}
        >
          {text}
        </div>
      )}
    </Space>
  );
}

export default LottieAnimationPlayer;
