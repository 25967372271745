import { createContext, useContext, useState } from "react";

const globalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  // Dashboard Profile states
  const [personalInfo, setPersonalInfo] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);

  const [globalDataLoaded, setGlobalDataLoaded] = useState({});
  const addGlobalDataLoaded = (type) => {
    setGlobalDataLoaded((prev) => ({ ...prev, [type]: true }));
  };

  const services = [
    {
      value: "bgr",
      label: "Remove Image background",
      active: true,
    },
    {
      value: "deblur",
      label: "Deblur images",
      active: true,
    },
    {
      value: "docscanner",
      label: "Docs scanner",
      active: true,
    },
    {
      value: "ocr",
      label: "Optical character recognition (OCR)",
      active: true,
    },
  ];
  const servicesValueLabel = {
    bgr: "Remove Image background",
    deblur: "Deblur images",
    docscanner: "Docs scanner",
    ocr: "Optical character recognition (OCR)",
  };

  return (
    <globalContext.Provider
      value={{
        globalDataLoaded,
        addGlobalDataLoaded,

        personalInfo,
        setPersonalInfo,
        companyInfo,
        setCompanyInfo,

        services,
        servicesValueLabel,
      }}
    >
      {children}
    </globalContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(globalContext);
};

export { useGlobalContext, GlobalContextProvider };
