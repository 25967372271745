import "./Style/antd.css";
import "./Style/globals.scss";

import RippleLoader from "./Components/Reusable/RippleLoader";
import { useAuthContext } from "./Components/Auth/AuthContext";

import AppRoutes from "./Routes";
import ProfileInfo from "./Components/PersonalAndCompanyInfo";
import { useEffect } from "react";
import constant from "./constant";

function App() {
  const {
    authLoading,
    user,
    showCompleteProfile,
    openCompleteProfile,
    closeCompleteProfile,
  } = useAuthContext();

  useEffect(() => {
    const showProfileCompleteCheck = () => {
      if (
        (user && !user?.profile_updated) ||
        (user &&
          user.role === constant.role.ROOT &&
          !user?.company_info_updated)
      ) {
        openCompleteProfile();
      }
    };

    showProfileCompleteCheck();
    //eslint-disable-next-line
  }, [user]);

  if (authLoading) {
    return <RippleLoader />;
  }

  return (
    <>
      <ProfileInfo
        visible={showCompleteProfile}
        onCancel={closeCompleteProfile}
      />
      <AppRoutes />
    </>
  );
}

export default App;
