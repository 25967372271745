import { Button, Space } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import classes from "./editCancelButtons.module.scss";

const EditCancelButtons = forwardRef(({ onEdit, onCancel, loading }, ref) => {
  const [editMode, setEditModal] = useState(false);

  const changeEditMode = () => setEditModal((prev) => !prev);

  const handleButtonClick = async (functionToFire) => {
    changeEditMode();
    if (functionToFire) {
      functionToFire();
    }
  };

  // We can pass changeEditMode function to parent to handle in parent by passing ref
  useEffect(() => {
    if (ref) {
      ref.current.changeEditMode = changeEditMode;
    }
  }, [ref]);

  return (
    <Space className={classes.container}>
      {!editMode ? (
        <Button
          loading={loading}
          type="primary"
          onClick={() => handleButtonClick(onEdit)}
        >
          <EditOutlined /> Edit
        </Button>
      ) : (
        <>
          <Button
            loading={loading}
            type="primary"
            danger
            onClick={() => handleButtonClick(onCancel)}
          >
            Cancel
          </Button>
          {/* Since we are using ant from no onClick function is added Form onFinish handler will take care of it */}
          <Button
            loading={loading}
            type="success"
            htmlType="submit"
            style={{
              backgroundColor: "#52c41a",
              borderColor: "#52c41a",
              color: "#fff",
            }}
          >
            Save
          </Button>
        </>
      )}
    </Space>
  );
});

export default EditCancelButtons;
