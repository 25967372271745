import { createContext, useContext, useEffect, useState } from "react";
import { getSubUsersAPI } from "../../../API/subUsers";
import { dashboardDataLoadTypes } from "../../../Components/contexts/constants";
import { useDashboardContext } from "../../../Components/contexts/dashboardContext";
import { useGlobalContext } from "../../../Components/contexts/globalContext";
import { handleErrors } from "../../../Utils/handleErrors";

const userManagementContext = createContext();

export const UserManagementContextProvider = ({ children }) => {
  const { services } = useGlobalContext();
  const { subUsers, setSubUsers, dashboardDataLoaded, addDashboardDataLoaded } =
    useDashboardContext();
  const [tableLoading, setTableLoading] = useState(false);
  const [editModalState, setEditModalState] = useState({
    open: false,
    username: "",
    addedPermissions: [],
  });

  useEffect(() => {
    if (!dashboardDataLoaded[dashboardDataLoadTypes.SUBUSERS]) {
      getSubUsers();
    }
    // eslint-disable-next-line
  }, []);

  // Get Subusers list
  const getSubUsers = async () => {
    setTableLoading(true);
    try {
      let response = await getSubUsersAPI();
      response = response?.data?.result.map((item) => ({
        ...item,
        key: item.id,
      }));
      setSubUsers(response);
      addDashboardDataLoaded(dashboardDataLoadTypes.SUBUSERS);
    } catch (error) {
      handleErrors(error);
    }
    setTableLoading(false);
  };

  const openEditModalState = (username) => {
    const userData = subUsers.find((item) => item.username === username);
    const addedPermissions = services.reduce((acc, curr) => {
      if (userData[curr.value]) {
        acc.push(curr.value);
      }
      return acc;
    }, []);
    setEditModalState({ open: true, username, addedPermissions });
  };

  const closeEditModalState = () => {
    setEditModalState({ open: false, username: "", addedPermissions: [] });
  };

  return (
    <userManagementContext.Provider
      value={{
        subUsers,
        getSubUsers,
        tableLoading,
        editModalState,
        openEditModalState,
        closeEditModalState,
      }}
    >
      {children}
    </userManagementContext.Provider>
  );
};

export const useUserManagementContext = () => {
  return useContext(userManagementContext);
};
