import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "../../phone-input";
import { updatePersonalInfoAPI } from "../../../../API/login";
import moment from "moment";
import { handleErrors } from "../../../../Utils/handleErrors";
import { useAuthContext } from "../../../Auth/AuthContext";
import constant from "../../../../constant";
import classes from "../userForm.module.scss";
import EditCancelButtons from "../../EditCancelButtons";
import LocationsSelectFormItem from "../../LocationsSelectFormItem";
import { formatDateYYYYMMDD } from "../../../../Utils/dates";
import { getPersonalInfoAPI } from "../../../../API/user";
import { useGlobalContext } from "../../../contexts/globalContext";
import { globalDataLoadTypes } from "../../../contexts/constants";
import Spinner from "../../../Reusable/Spinner";

const constants = {
  PROFILE_PAGE: "profilePage",
};

const PersonalInfoForm = ({ from }) => {
  const { user, setProfileUpdated, changeUserName } = useAuthContext();
  const [editable, setEditable] = useState(
    from === constants.PROFILE_PAGE ? false : true
  );
  const {
    personalInfo,
    setPersonalInfo,
    globalDataLoaded,
    addGlobalDataLoaded,
  } = useGlobalContext();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [fetchingPersonalInfo, setFetchingPersonalInfo] = useState(false);
  // To get change editMode functuin from child editCancelButtons
  const EditCancelButtonsRef = useRef({});

  const getPersonalInfoData = async () => {
    setFetchingPersonalInfo(true);
    try {
      const response = await getPersonalInfoAPI();
      const { data } = response;
      data.date_of_birth = moment(response.data.date_of_birth, "YYYY-MM-DD");
      data.country = JSON.stringify({
        name: data.country,
        code: data.country_code,
      });
      setPersonalInfo(response.data);
      addGlobalDataLoaded(globalDataLoadTypes.PERSONAL_INFO);
    } catch (error) {
      handleErrors(error);
    }
    setFetchingPersonalInfo(false);
  };

  const handleCancelSave = () => {
    form.setFieldsValue(personalInfo);
    toNonEditableMode();
  };

  useEffect(() => {
    if (
      from === constants.PROFILE_PAGE &&
      !globalDataLoaded[globalDataLoadTypes.PERSONAL_INFO]
    ) {
      getPersonalInfoData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (personalInfo) {
      form.setFieldsValue(personalInfo);
    }
    // eslint-disable-next-line
  }, [personalInfo]);

  const handleSubmit = async (values) => {
    values.date_of_birth = formatDateYYYYMMDD(Number(values.date_of_birth));
    const countryData = JSON.parse(values.country);
    values.country = countryData.name;
    values.country_code = countryData.code;
    setSubmitting(true);
    try {
      await updatePersonalInfoAPI(values);
      if (from === constants.PROFILE_PAGE) {
        toNonEditableMode();
        EditCancelButtonsRef.current.changeEditMode();
        message.success("Personal info updated");
      } else {
        setProfileUpdated();
        // if (user.role === constant.role.SUB_USER) {
        //   closeCompleteProfile();
        //   navigate("/dashboard/dashboard");
        // }
      }
      changeUserName(values.first_name, values.last_name);
    } catch (error) {
      handleErrors(error);
    }
    setSubmitting(false);
  };

  const toEditableMode = () => setEditable(true);
  const toNonEditableMode = () => setEditable(false);

  return (
    <>
      {fetchingPersonalInfo && <Spinner width={"150px"} />}
      <Form
        className={`${
          from === "profilePage" ? classes.profileFormContainer : ""
        } ${!editable ? classes["readOnly"] : ""}`}
        form={form}
        onFinish={handleSubmit}
        layout={from === constants.PROFILE_PAGE ? "horizontal" : "vertical"}
        labelAlign="left"
        {...(from === constants.PROFILE_PAGE && {
          labelCol: { span: 4 },
          wrapperCol: { span: 20 },
        })}
      >
        {from === constants.PROFILE_PAGE && (
          <>
            <Row
              className={classes.profileHead}
              align="middle"
              justify="space-between"
            >
              <Col className={classes.pageTitle}>Personal Information</Col>
              <Col>
                <EditCancelButtons
                  onEdit={toEditableMode}
                  onCancel={handleCancelSave}
                  loading={submitting}
                  ref={EditCancelButtonsRef}
                />
              </Col>
            </Row>
            <Divider style={{ margin: "10px 0 30px 0" }} />
          </>
        )}

        <Form.Item
          name="first_name"
          label="First name"
          rules={[{ required: true, message: "Please enter first name." }]}
        >
          <Input disabled={!editable} placeholder="First name" />
        </Form.Item>

        <Form.Item
          name="last_name"
          label="Last name"
          rules={[{ required: true, message: "Please enter last name." }]}
        >
          <Input disabled={!editable} placeholder="Last name" />
        </Form.Item>

        <PhoneInput disabled={!editable} />

        <LocationsSelectFormItem
          type={"country"}
          rules={[{ required: true, message: "Please select country." }]}
          disabled={!editable}
        />

        <Form.Item
          name="date_of_birth"
          label="Date of birth"
          rules={[{ required: true, message: "Select date of birth" }]}
        >
          <DatePicker
            style={{ width: "100%" }}
            disabled={!editable}
            format="YYYY-MM-DD"
          />
        </Form.Item>

        {from !== constants.PROFILE_PAGE && (
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={submitting}
              className={`full-width`}
            >
              {user.role === constant.role.ROOT ? "Next" : "Submit"}
            </Button>
          </Form.Item>
        )}
      </Form>
    </>
  );
};
export default PersonalInfoForm;
