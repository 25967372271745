import { Outlet } from "react-router-dom";
import classes from "./mainLayout.module.scss";
import MainHeader from "./MainHeader";

const MainLayout = ({ children }) => {
  return (
    <>
      <MainHeader />
      <div
        className={classes.mainLayoutContainer}
        style={{
          background:
            "url(https://www.media.io/assetsdir/MicrosoftTeams-image.e898366e.jpg)",
        }}
      >
        <Outlet />
        {children}
      </div>
    </>
  );
};

export default MainLayout;
