import { Button, Col, Form, Input, Row, Divider, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../../Auth/AuthContext";
import { handleErrors } from "../../../../Utils/handleErrors";
import {
  getCompanyDetailsAPI,
  updateCompanyDetailsAPI,
} from "../../../../API/login";
import LocationsSelectFormItem from "../../LocationsSelectFormItem";
// import EditCancelButtons from "../../EditCancelButtons";
import classes from "../userForm.module.scss";
import { useGlobalContext } from "../../../contexts/globalContext";
import { globalDataLoadTypes } from "../../../contexts/constants";
import Spinner from "../../Spinner";
import EditCancelButtons from "../../EditCancelButtons";


const validateMessages = {
  // eslint-disable-next-line
  required: "${label} is required!",
  types: {
    // eslint-disable-next-line
    number: "${label} is not a valid number!",
  },
};

const constants = {
  PROFILE_PAGE: "profilePage",
};

const CompanyInfoForm = ({ from }) => {
  const { setCompanyInfoUpdated } = useAuthContext();
  const [editable, setEditable] = useState(
    from === constants.PROFILE_PAGE ? false : true
  );  const { companyInfo, setCompanyInfo, globalDataLoaded, addGlobalDataLoaded } =
    useGlobalContext();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [fetchingInfo, setFetchingInfo] = useState(false);
  // To get change editMode functuin from child editCancelButtons
  const EditCancelButtonsRef = useRef({});
  // //For edit mode
  // To get change editMode functuin from child editCancelButtons
  // const EditCancelButtonsRef = useRef({});

  const getCompanyInfoData = async () => {
    setFetchingInfo(true);
    try {
      const response = await getCompanyDetailsAPI();
      const data = response.data[0];
      data.country = JSON.stringify({
        name: data.country,
        code: data.country_code,
      });

      // //For edit mode
      // data.state = JSON.stringify({
      //   name: data.state,
      //   code: data.state_code,
      // });

      setCompanyInfo(data);
      addGlobalDataLoaded(globalDataLoadTypes.COMPANY_INFO);
    } catch (error) {
      handleErrors(error);
    }
    setFetchingInfo(false);
  };

  //For edit mode
  const handleCancelSave = () => {
    form.setFieldsValue(companyInfo);
    toNonEditableMode();
  };

  useEffect(() => {
    if (
      from === constants.PROFILE_PAGE &&
      !globalDataLoaded[globalDataLoadTypes.COMPANY_INFO]
    ) {
      getCompanyInfoData();
    }
    // eslint-disable-next-line
  }, []);

  // //For edit mode
  // const toEditableMode = () => setEditable(true);
  // const toNonEditableMode = () => setEditable(false);

  useEffect(() => {
    if (companyInfo) {
      form.setFieldsValue(companyInfo);
    }
    // eslint-disable-next-line
  }, [companyInfo]);

  const onFinish = async (values) => {
    const countryData = JSON.parse(values.country);
    const stateData = JSON.parse(values.state);
    values.country = countryData.name;
    values.state = stateData.name;
    values.country_code = countryData.code;
    values.state_code = stateData.name;
    setSubmitting(true);
    try {
      await updateCompanyDetailsAPI(values);
      if (from === constants.PROFILE_PAGE) {
        toNonEditableMode();
        EditCancelButtonsRef.current.changeEditMode();
        message.success("Company info updated");
      } else {
        setCompanyInfoUpdated();
        // closeCompleteProfile();
        // navigate("/dashboard/dashboard");
      }
    } catch (error) {
      handleErrors(error);
    }
    setSubmitting(false);
  };

  const toEditableMode = () => setEditable(true);
  const toNonEditableMode = () => setEditable(false);


  return (
    <>
      {fetchingInfo && <Spinner width={"150px"} />}
      <Form
        layout={from === constants.PROFILE_PAGE ? "horizontal" : "vertical"}
        className={`${
          from === "profilePage" ? classes.profileFormContainer : ""
        } ${!editable ? classes["readOnly"] : ""}`}
        onFinish={onFinish}
        validateMessages={validateMessages}
        form={form}
        labelAlign="left"
        {...(from === constants.PROFILE_PAGE && {
          labelCol: { span: 4 },
          wrapperCol: { span: 20 },
        })}
      >
        {from === constants.PROFILE_PAGE && (
          <>
            <Row
              className={classes.profileHead}
              align="middle"
              justify="space-between"
            >
              <Col className={classes.pageTitle}>Company Information</Col>

              {/* For edit mode */}
              <Col>
                <EditCancelButtons
                  onEdit={toEditableMode}
                  onCancel={handleCancelSave}
                  loading={submitting}
                  ref={EditCancelButtonsRef}
                />
              </Col>
            </Row>
            <Divider style={{ margin: "10px 0 30px 0" }} />
          </>
        )}
        <Form.Item
          label="Company name"
          name="company"
          rules={[{ required: true }]}
        >
          <Input disabled={!editable} placeholder="Enter your company name" />
        </Form.Item>

        <LocationsSelectFormItem
          type={"country"}
          rules={[{ required: true }]}
          populateStates
          populateStatesRules={[{ required: true }]}
          disabled={!editable}
        />

        <Form.Item label="Address" name="address" rules={[{ required: true }]}>
          <Input disabled={!editable} placeholder="Enter address" />
        </Form.Item>

        <Form.Item label="Zip code" name="zipcode" rules={[{ required: true }]}>
          <Input disabled={!editable} placeholder="Enter your zip code" />
        </Form.Item>

        {from !== constants.PROFILE_PAGE && (
          <Form.Item>
            <Button
              className={`full-width`}
              loading={submitting}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
    </>
  );
};

export default CompanyInfoForm;
