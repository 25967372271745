import { Dropdown, Menu, Space } from "antd";
import { useAuthContext } from "../../Auth/AuthContext";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";

const profileIcon = [
  <svg
    width="35"
    height="35"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#262525"
    ></path>
  </svg>,
];

function UserProfileMenus({ forDashboard = false }) {
  return (
    <>
      <Dropdown arrow overlay={<Menus />} placement="bottom">
        <Space size={0} align="center" style={{ cursor: "pointer" }}>
          {profileIcon} &nbsp;
          {forDashboard && "My profile"}
        </Space>
      </Dropdown>
    </>
  );
}

export default UserProfileMenus;

function Menus() {
  const { user, logout } = useAuthContext();

  // const confirmLogout = () => {
  //   modalConfirm(logout, "Are you sure you want to logout?");
  // };

  const menuData = [
    {
      label: `${user.role} User`,
      key: 1,
      disabled: true,
      icon: <UserOutlined />,
    },
    {
      label: user.username,
      key: 2,
      disabled: true,
      icon: <UserOutlined />,
    },
    {
      label: "Logout",
      key: 3,
      danger: true,
      onClick: logout,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Menu>
      {menuData.map((item) => {
        const { label, key, onClick, danger, icon } = item;

        return (
          <Menu.Item
            key={key}
            style={{ fontSize: "1rem" }}
            onClick={onClick}
            danger={danger}
            icon={icon}
          >
            {label}
          </Menu.Item>
        );
      })}
    </Menu>
  );
}
