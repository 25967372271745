import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../Auth/AuthContext";

const DashboardButton = () => {
  const { openCompleteProfile, user } = useAuthContext();
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (user.profile_updated && user.company_info_updated) {
      navigate("/dashboard/dashboard");
    } else {
      openCompleteProfile();
    }
  };

  return (
    <>
      <Button type="primary" shape="round" onClick={handleNavigation}>
        Dashboard
      </Button>
    </>
  );
};

export default DashboardButton;
