import axios from "axios";

export const mainServerAxios = axios.create({
    baseURL: process.env.REACT_APP_MAIN_SERVER_URL,
});

mainServerAxios.interceptors.request.use((req) => {
    let userToken = localStorage.getItem("access_token");
    if (!userToken) {
        userToken = sessionStorage.getItem("access_token")
    }
    if (userToken) {
        req.headers.Authorization = `Bearer ${userToken}`;
        req.headers.token = userToken;
    }
    return req;
});
