import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "../Components/Layout/DashboardLayout";
import MainLayout from "../Components/Layout/MainLayout";
import RippleLoader from "../Components/Reusable/RippleLoader";
import { UserManagementContextProvider } from "../Pages/Dashboard/UserManagment/context";
import CommingSoon from "../Components/CommingSoon";

const AILabPage = lazy(() => import("../Pages/AILab/index"));
const UserEntryPage = lazy(() => import("../Pages/UserEntry"));
const EmailVerifyPage = lazy(() => import("../Pages/EmailVerify"));
const Menus = lazy(() => import("../Pages/AILab/Menus"));
const Feature = lazy(() => import("../Pages/AILab/Feature"));
const NoPage = lazy(() => import("../Pages/NoPage"));
//Dashboard pages
const DashboardHome = lazy(() => import("../Pages/Dashboard/Home"));
const UserManagment = lazy(() => import("../Pages/Dashboard/UserManagment"));
const Profile = lazy(() => import("../Pages/Dashboard/Profile"));
const Plans = lazy(() => import("../Pages/Dashboard/Plans"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<RippleLoader />}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={<AILabPage />}>
            <Route index element={<Menus />} />
            <Route path="/feature" element={<Feature />} />
          </Route>
          <Route path="/login" element={<UserEntryPage type="login" />} />
          <Route path="/signup" element={<UserEntryPage type="signup" />} />
          <Route
            path="/subuser-setPassword/:token"
            element={<UserEntryPage type="subuserSetPassword" />}
          />
          <Route
            path="/forgot-password"
            element={<UserEntryPage type="forgotPassword" />}
          />
          <Route
            path="/email-verification/:token"
            element={<EmailVerifyPage />}
          />
          <Route
            path="/password-reset/:token"
            element={<UserEntryPage type="setForgotPassword" />}
          />
          <Route path="/comingsoon" element={<CommingSoon />} />
        </Route>

        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Navigate to="/dashboard/dashboard" />} />
          <Route
            // Because the sidenav active link has issue if we added only dashboard
            path="/dashboard/dashboard"
            element={<DashboardHome />}
          />
          <Route
            path="/dashboard/user-managment"
            element={
              <UserManagementContextProvider>
                <UserManagment />
              </UserManagementContextProvider>
            }
          />
          <Route path="/dashboard/profile" element={<Profile />} />
          <Route path="/dashboard/plans" element={<Plans />} />
        </Route>

        <Route
          path="*"
          element={
            <MainLayout>
              <NoPage />
            </MainLayout>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
